import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import IconPreview from 'components/IconPreview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Chameleon Design System provides a consistent set of multibranded icons. Every
icon is designed with a uniform bounding box that guarantees consistent
placement and visual balance.`}</p>
    <p>{`We strongly believe that iconography also has a fundamental impact on a brand's
visual expression. Therefore, Chameleon's Iconography System ensures that each
brand shares the same base set of icons, where every brand has te option to
extend it with a custom icon set. This way we encourage them to contribute to
the design system and helping to translate their visual langue into Chameleon's
multibranding concept.`}</p>
    <h2 {...{
      "id": "browse-icons",
      "style": {
        "position": "relative"
      }
    }}>{`Browse icons`}</h2>
    <IconPreview mdxType="IconPreview" />
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Please check out the `}<a parentName="p" {...{
        "href": "/components/icon/design"
      }}>{`guidelines`}</a>{` on how to use them.`}</p>
    <h2 {...{
      "id": "not-finding-the-right-icon",
      "style": {
        "position": "relative"
      }
    }}>{`Not finding the right icon?`}</h2>
    <p>{`Chameleon Design System is a living thing. Therefore, the situation may occur
that certain icons do not yet exist. Please see our
`}<a parentName="p" {...{
        "href": "/about/team/"
      }}>{`Getting in touch`}</a>{` page for information on how to reach out. If
there's a similar icon already, we will suggest you to use a existing one
instead.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      